<template>
	<div>
		<Formulaire />
	</div>
</template>

<script>
import Formulaire from "./components/Formulaire.vue";

export default {
	name: "App",
	components: {
		Formulaire,
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}
</style>
